/** @jsx jsx */
import { jsx, useTheme, css } from "@emotion/react";
import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import {
  Box,
  useThemeProps,
  Excerpt,
  Heading,
  Link,
  Stack,
  Button,
} from "@wsui/base";

export default function ResearchGroupArchiveHit(props) {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  props = useThemeProps({ props, name: "AlgoliaResearchGroupArchiveHit" });
  props = useThemeProps({ props, name: "AlgoliaHit" });
  const { getPlainTextExcerpt } = useHTMLProcessor();
  let {
    hit,
    // eslint-disable-next-line no-unused-vars
    hitsOwnerState,
    ...restProps
  } = props;
  let { url, title, excerpt, content, researchTeams } = hit;
  return (
    <Box as="li" horizontalContentMaxWidth="none" {...restProps}>
      <Stack spacing={[3.5, 7]} divider={true}>
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr max-content;
            align-items: start;
          `}
        >
          <div>
            <Heading variant="h3">
              <Link url={url} color="evergreen" underline="none">
                {title}
              </Link>
            </Heading>
            <Excerpt variant="description" lines={2}>
              {getPlainTextExcerpt({ excerpt, content })}
            </Excerpt>
            {/* <Typography variant="meta">{contentTypeLabel}</Typography> */}
          </div>
          <Button url={url} variant="primary">
            {"View group"}
          </Button>
        </div>
        {researchTeams?.map(({ title, excerpt, uri }, index) => {
          return (
            <div
              key={index}
              css={css`
                display: grid;
                grid-template-columns: 1fr max-content;
                align-items: start;
              `}
            >
              <div>
                <Heading variant="h3">
                  <Link url={uri} color="evergreen" underline="none">
                    {title}
                  </Link>
                </Heading>
                <Excerpt variant="description" lines={2}>
                  {getPlainTextExcerpt({ excerpt })}
                </Excerpt>
                {/* <Typography variant="meta">{contentTypeLabel}</Typography> */}
              </div>
              <Button url={uri} variant="primary">
                {"View team"}
              </Button>
            </div>
          );
        })}
      </Stack>
    </Box>
  );
}
