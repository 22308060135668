/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import { Icon, useClickable, useFocusTrap, useThemeProps } from "@wsui/base";
import { forwardRef } from "react";

function SlidePanelAction(inProps) {
  inProps = useThemeProps({
    props: inProps,
    name: "SlidePanelAction",
  });
  const theme = useTheme();
  const { Element, props } = useClickable(inProps);
  let { round, color = "gray.700", ...restProps } = props;
  return (
    <Element
      css={css`
        ${theme.styleUtils.buttonReset}

        border: 1px solid transparent;
        cursor: pointer;

        padding-block: ${theme.getLength(1.5)};
        padding-inline: ${theme.getLength(round ? 1.5 : 2)};
        border-radius: ${round ? "9999px" : theme.getLength(1)};

        align-self: center;

        color: ${theme.getColor(color)};

        &:hover {
          background-color: ${theme.getColor([color, 200])};
        }
      `}
      {...restProps}
    />
  );
}

export default forwardRef(function SlidePanel(props, inRef) {
  props = useThemeProps({
    props,
    name: "SlidePanel",
  });
  let {
    children,
    open,
    transitionDuration = "200ms",
    blurryBackground = true,
    onClose,
    title,
    id = "slide-panel",
    ...restProps
  } = props;

  const [focusTrapRef] = useFocusTrap(open);
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      onClose();
      event.stopPropagation();
    }
  };
  return (
    <div
      css={css`
        position: fixed;
        inset: 0;
        z-index: 100;
        pointer-events: ${open ? "auto" : "none"};
      `}
      aria-hidden={!open}
      onKeyDown={handleKeyDown}
      ref={inRef}
    >
      <div
        onClick={onClose}
        css={css`
          position: absolute;
          inset: 0;
          background-color: rgba(0, 0, 0, 0.5);
          opacity: ${open ? 1 : 0};
          transition-duration: ${transitionDuration};
          ${blurryBackground && `backdrop-filter: blur(${open ? "2em" : "0"})`};
        `}
      />
      <div
        css={css`
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: white;
          transform: translateX(${open ? "0%" : "100%"});
          transition-duration: ${transitionDuration};
          z-index: 1;
          max-width: 100%;
        `}
      >
        <div
          css={css`
            visibility: ${open ? "visible" : "hidden"};
            transition-property: visibility;
            transition-duration: ${transitionDuration};
            transition-timing-function: ${open ? "step-start" : "step-end"};
          `}
          ref={focusTrapRef}
          tabIndex={-1}
          id={id}
          aria-labelledby={title && `${id}-title`}
          {...restProps}
        >
          {!!(title || onClose) && (
            <div
              css={css`
                display: grid;
                padding: 1rem 0.875rem;
                align-items: center;

                ${!!title && !!onClose
                  ? `grid-template-columns: 1fr auto;`
                  : `justify-items: end;`}
              `}
            >
              {!!title && (
                <div
                  css={css`
                    padding-inline: 1.25rem;
                  `}
                  id={`${id}-title`}
                >
                  {title}
                </div>
              )}
              {!!onClose && (
                <SlidePanelAction
                  onClick={onClose}
                  css={css`
                    align-self: start;
                  `}
                >
                  <Icon name="close" size={8} aria-label={"Close"} />
                </SlidePanelAction>
              )}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
});
