/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Clickable } from "@wsui/base";

export default function HeaderLogo({ link, logo, alt, ...restProps }) {
  link = typeof link === "string" ? { href: link } : link;
  return (
    <div
      {...restProps}
      css={css`
        position: relative;
        display: inline-flex;
      `}
    >
      <Clickable
        {...link}
        css={css`
          color: inherit;
          text-decoration: inherit;
          position: relative;
          z-index: 1;
        `}
      >
        <img
          src={logo}
          alt={alt}
          css={css`
            display: block;
          `}
        />
      </Clickable>
    </div>
  );
}
