/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import { ModuleWrapper } from "@municipio/gatsby-theme-basic/src/wsui/components";
import {
  useThemeProps,
  parseStyle,
  Link,
  ListStack,
  Heading,
  Typography,
  Stack,
} from "@wsui/base";

export default function PublicationsModule(props) {
  let {
    urlColor = "black",
    style,
    title,
    ...restProps
  } = useThemeProps({
    props,
    name: "PublicationsModule",
  });
  const theme = useTheme();

  const publicationItems = props.module.publications.publications.map(
    (item, i) => (
      <Stack
        key={i}
        spacing={3}
        css={css`
          margin-top: ${i === 0 ? "0" : theme.getLength(4.5)};
        `}
      >
        <Heading
          variant="h4"
          maxLineLength="none"
          css={css`
            color: ${theme.getColor(urlColor)};
          `}
        >
          {item?.link?.url ? (
            <Link href={item.link.url}>{item.title}</Link>
          ) : (
            item.title
          )}
        </Heading>
        <Typography variant="description" maxLineLength="none">
          {item.reference}
        </Typography>
        <Typography variant="description" maxLineLength="none">
          {item.authors}
        </Typography>
      </Stack>
    ),
  );

  return (
    <ModuleWrapper title={title} {...restProps}>
      <ListStack
        css={css`
          ${parseStyle(style, theme)}
        `}
        divider={true}
        spacing={3}
      >
        {publicationItems}
      </ListStack>
    </ModuleWrapper>
  );
}
