/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import {
  InlineList,
  Link,
  useThemeProps,
  handleComponentsProp,
  PageGridItem,
  PageGrid,
  parseStyle,
} from "@wsui/base";

const HeaderHelpMenuRoot = styled("div")``;

const DefaultLink = Link;

export default function HeaderHelpMenu(props) {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  props = useThemeProps({ props, name: "HeaderHelpMenu" });
  let { menu = "HELP_MENU", components, style, ...restProps } = props;

  let { Link = DefaultLink } = handleComponentsProp(components, {
    Link: DefaultLink,
  });
  let items = useMenu(menu)?.items;

  if (!items?.length) return null;

  return (
    <HeaderHelpMenuRoot
      {...restProps}
      css={css`
        ${parseStyle(style, theme)};
      `}
    >
      <PageGrid>
        <PageGridItem colspan={12}>
          <InlineList overflow="hidden" spacing={[4, 8]} align="end">
            {items.map(({ url, label, target }, index) => {
              return (
                <Link url={url} target={target} key={index}>
                  {label}
                </Link>
              );
            })}
          </InlineList>
        </PageGridItem>
      </PageGrid>
    </HeaderHelpMenuRoot>
  );
}
