/** @jsx jsx */
import { jsx, css, useTheme } from "@emotion/react";
import { IsomorphicSuspense } from "@wsui/base";
import { lazy } from "react";
import { useTranslation } from "react-i18next";

import SlidePanel from "../../../../wsui/components/SlidePanel.jsx";

const HeaderHamburgerMenuPanel = lazy(() =>
  import("./HeaderHamburgerMenuPanel.jsx"),
);

export default function HeaderHamburgerMenu({ id, ...restProps }) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <SlidePanel
      id={id}
      blurryBackground={false}
      css={css`
        display: flex;
        flex-flow: column;
        height: 100%;
        min-width: min(100vw, 25rem);

        @media screen and (max-width: 500px) {
          width: 100vw;
        }
      `}
      {...restProps}
    >
      <IsomorphicSuspense fallback={<div>{t("loading")}</div>}>
        <HeaderHamburgerMenuPanel
          css={css`
            overflow-y: auto;
            overflow-x: hidden;
            flex: 1;
          `}
        />
      </IsomorphicSuspense>
    </SlidePanel>
  );
}
