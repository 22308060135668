/** @jsx jsx */
import { jsx, css, useTheme } from "@emotion/react";
import {
  useThemeProps,
  Button,
  Heading,
  TypographyBlock,
  Section,
  parseStyle,
  Stack,
  InlineList,
  Box,
  Image,
  PageGrid,
  PageGridItem,
  BackgroundColorContextProvider,
  generateUniqueId,
} from "@wsui/base";
import { omit } from "lodash/fp";
import { Fragment, useState } from "react";

HeroBillboardModule.wsuiConfig = {
  hasCaption: false,
};

export default function HeroBillboardModule(props) {
  const theme = useTheme();
  props = useThemeProps({
    props,
    name: "MunicipioHeroBillboardModule",
  });

  let {
    content,
    links,
    color,
    // align,
    image: { dominantColor, ...image } = {},
    // imageAspectRatio = 16 / 9,
    imagePlacement = "left",
    title,
    style,
    titleVariant = "caption",
    // horizontalContentMaxWidth = "100%",
    // mediaColspan = 6,
    buttonProps,
    contentGap = 5,
    contentElementMapping = { p: "description" },
    beforeTitle = null,
    headingId,
    ...restProps
  } = omit(
    [
      "align",
      "buttonMarginTop",
      "imageAspectRatio",
      "mediaColspan",
      "horizontalContentMaxWidth",
      "description",
      "format",
    ],
    props,
  );

  const [defaltHeadingId] = useState(
    () => `module-heading-${generateUniqueId()}`,
  );
  headingId = headingId || defaltHeadingId;

  let MaybeSection = title ? Section : Fragment;

  return (
    <article
      css={css`
        ${parseStyle(style, theme)}
        position: relative;
        width: 100%;
        display: grid;
        overflow: hidden;
        align-items: center;
        justify-items: center;
        background-color: ${dominantColor};
      `}
      {...restProps}
    >
      <BackgroundColorContextProvider backgroundColor={dominantColor}>
        <Image
          {...image}
          css={css`
            grid-row: 1;
            grid-column: 1;
            min-width: 100%;
            min-height: 100%;
            aspect-ratio: 16/9;
            max-height: 75vh;
            display: grid;
            align-content: center;
          `}
        />
        <PageGrid
          css={css`
            grid-row: 1;
            grid-column: 1;
            position: relative;
            padding-block: 1rem;
          `}
          justify="start"
          rowGap={0}
        >
          {imagePlacement === "left" && (
            <PageGridItem colspan={6}>
              <div />
            </PageGridItem>
          )}
          <PageGridItem colspan={6}>
            <Box color={color} padding={[6, 12]}>
              <Stack spacing={contentGap}>
                {beforeTitle}
                {!!title && (
                  <Heading variant={titleVariant} id={headingId}>
                    {title}
                  </Heading>
                )}
                <MaybeSection>
                  <TypographyBlock
                    maxBlockMargin={contentGap}
                    elementMapping={contentElementMapping}
                  >
                    {content}
                  </TypographyBlock>
                  {!!links?.length && (
                    <InlineList>
                      {links.map(({ link, variant = "primary" }, index) => (
                        <Button
                          key={index}
                          {...link}
                          variant={variant}
                          {...buttonProps}
                        />
                      ))}
                    </InlineList>
                  )}
                </MaybeSection>
              </Stack>
            </Box>
          </PageGridItem>
          {imagePlacement === "right" && (
            <PageGridItem colspan={6}>
              <div />
            </PageGridItem>
          )}
        </PageGrid>
      </BackgroundColorContextProvider>
    </article>
  );
}
