/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import { useThemeProps, Icon } from "@wsui/base";
import { useTranslation } from "react-i18next";
// import PropTypes from "prop-types";

// HeaderSearchField.propTypes = {
//   menuName: PropTypes.string,
//   color: PropTypes.string,
// };

export default function HeaderSearchField(props) {
  const theme = useTheme();
  const { t } = useTranslation();
  props = useThemeProps({ name: "HeaderSearchField", props });
  let { padding, ...restProps } = props;

  return (
    <form method="GET" action="/search" css={css``} {...restProps}>
      <label
        htmlFor="site-search"
        css={css`
          position: absolute;
          clip: rect(0, 0, 0, 0);
          pointer-events: none;
        `}
      >
        Search
      </label>
      <div
        css={css`
          position: relative;
          display: grid;
          align-items: center;

          & > * {
            grid-column: 1;
            grid-row: 1;
          }
        `}
      >
        <Icon
          name="search"
          size={4}
          color={"#00414b"}
          css={css`
            pointer-events: none;
            margin-left: 1.25rem;
          `}
        />
        <input
          id="site-search"
          type="search"
          name="query"
          placeholder={t("siteSearchTitle")}
          css={css`
            padding: ${theme.getLength(padding)};
            border: none;
            border-radius: 6px;
            background-color: ${theme.getColor("gray")};
          `}
        />
      </div>
      <button
        type="submit"
        css={css`
          position: absolute;
          clip: rect(0, 0, 0, 0);
          pointer-events: none;
        `}
      >
        Search
      </button>
    </form>
  );
}
