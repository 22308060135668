/** @jsx jsx */
import { jsx, css, useTheme } from "@emotion/react";
import { HitController as DefaultHitComponent } from "@wsui/algolia";
import { Grid, useThemeProps } from "@wsui/base";

export default function EventArchiveHits(props) {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  props = useThemeProps({ props, name: "EventArchiveHits" });
  props = useThemeProps({ props, name: "Hits" });
  let {
    hitComponent: Hit = DefaultHitComponent,
    hits,
    columnGap = [4, 8],
    rowGap = [8.75, 17.5],
    ownerState,
    size = 80,
    ...restProps
  } = props;

  return (
    <Grid
      css={css`
        align-items: stretch;
        height: 100%;
      `}
      columnGap={columnGap}
      rowGap={rowGap}
      size={size}
      columnCount="auto-fill"
      {...restProps}
    >
      {hits.map((hit, index) => (
        <Hit key={index} hit={hit} hitsOwnerState={ownerState} />
      ))}
    </Grid>
  );
}
