/** @jsx jsx */
import { css, jsx } from "@emotion/react";

export default function BreadcrumbsDescription({ description, ...restProps }) {
  return (
    <span
      {...restProps}
      css={css`
        font-weight: lighter;
      `}
    >
      <span
        css={css`
          font-weight: bold;
        `}
      >
        You are here
      </span>{" "}
      /
    </span>
  );
}
