/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import useFooterBlocks from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/useFooterBlocks";
import FooterBlockController from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components/FooterBlockController.jsx";
import DefaultLogo from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components/FooterLogo.jsx";
import {
  Clickable,
  InlineList,
  PageGrid,
  PageGridItem,
  Stack,
  handleComponentsProp,
  useThemeProps,
} from "@wsui/base";

import FooterLogo from "../../../../FooterLogo.jsx";
import logoCmm from "../../../../images/logo-evergreen.svg";
import logoKarolinska from "../../../../images/logo-karolinska-universitetssjukhuset.svg";
import logoKi from "../../../../images/logo-ki.svg";

const DefaultLink = styled(Clickable)`
  text-decoration: inherit;
  color: inherit;
  font-weight: 600;
  font-size: ${({ theme }) => theme.getLength([3.25, 3.75])};
  &.wsui-is-interactive:hover,
  &:focus-visible {
    text-decoration: underline;
  }
`;

export default function Footer(inProps) {
  const theme = useTheme();
  const {
    color = "primary.800",
    bottomColor,
    spacing,
    gap = [7.5, 15],
    blocksMargin = [12, 12],
    bannerMargin = 4,
    bannerBorderColor = "white",
    justifyContent = "space-between",
    components,
    legalMenu = "LEGAL",
    ...restProps
  } = useThemeProps({
    props: inProps,
    name: "Footer",
  });
  let { LegalMenuLink, Logo } = handleComponentsProp(components, {
    LegalMenuLink: DefaultLink,
    Logo: DefaultLogo,
  });
  const columns = useFooterBlocks();

  let legalMenuItems = useMenu(legalMenu)?.items;

  const logos = [
    { logo: logoCmm, alt: "CMM" },
    { logo: logoKi, alt: "Karolinska institutet" },
    { logo: logoKarolinska, alt: "Karolinska universitetssjukhuset" },
  ];

  return (
    <footer
      css={css`
        background: ${theme.getColor(color)};
        color: ${theme.getColor([color, "text"])};
      `}
      {...restProps}
    >
      <PageGrid
        spacing={spacing}
        rowGap={blocksMargin}
        gap={gap}
        css={css`
          padding-block: ${theme.getLength(blocksMargin)};
        `}
      >
        {columns.map(({ blocks, colspan }, columnIndex) => (
          <PageGridItem key={columnIndex} colspan={[colspan, 12]}>
            <Stack justifyContent="start" direction="column">
              {blocks.map((block, blockIndex) => (
                <FooterBlockController key={blockIndex} block={block} />
              ))}
            </Stack>
          </PageGridItem>
        ))}
      </PageGrid>
      <PageGrid
        css={css`
          background: ${theme.getColor(bottomColor ? bottomColor : color)};
          color: ${theme.getColor([color, "text"])};
        `}
        // spacing={spacing}
        // rowGap={blocksMargin}
      >
        <PageGridItem colspan={12}>
          <div
            css={css`
              padding-block: ${theme.getLength(bannerMargin)};
              display: flex;
              justify-content: ${justifyContent};
              flex-wrap: wrap;
              gap: ${theme.getLength(gap)};
              align-items: center;
              border-top: 1px solid ${theme.getColor(bannerBorderColor)};
            `}
          >
            {
              !!legalMenuItems?.length && null
              // <InlineList
              //   overflow="visible"
              //   spacing={[4, 8]}
              //   css={css`
              //     flex-shrink: 1;
              //   `}
              // >
              //   {legalMenuItems.map(({ url, label, target }, index) => {
              //     return (
              //       <LegalMenuLink url={url} target={target} key={index}>
              //         {label}
              //       </LegalMenuLink>
              //     );
              //   })}
              // </InlineList>
            }
            {logos ? (
              logos.map((logo, i) => (
                <FooterLogo
                  key={i}
                  logo={logo.logo}
                  alt={logo.alt}
                  css={css`
                    &:nth-of-type(1) {
                      img {
                        height: 43px;
                      }
                    }
                    &:nth-of-type(2) {
                      img {
                        height: 51px;
                      }
                    }
                    &:nth-of-type(3) {
                      img {
                        height: 38px;
                      }
                    }
                  `}
                />
              ))
            ) : (
              <Logo />
            )}
          </div>
        </PageGridItem>
      </PageGrid>
    </footer>
  );
}
